import store from '../../../store'

/* eslint-disable import/prefer-default-export */
export const getChartProcessedInfo = (results, type) => {
  if (!results) return null
  const data = {}
  results.forEach((result, index) => {
    const form = result[`Evaluación de Desempeño Laboral${type}`].empty
    const positionQuestion = form.empty[type === '' ? 2 : 1]
    const positionOptions = positionQuestion.options

    const sections = Object.keys(form).filter(key => key !== 'empty')
    sections.forEach(section => {
      const questions = form[section]
      const totalQuestions = questions.length
      if (totalQuestions === 0) return null
      const sum = questions.reduce((x, y) => x + (y.answers[0].body?.answer === '0' ? y.steps.length * 20 : y.answers[0].body?.answer * 20), 0)
      const avg = Math.round(sum / totalQuestions)
      data[section] = { ...data[section], [`${positionOptions[positionQuestion.answers[0].body?.answer]}:${index}`]: avg }
      return true
    })
  })
  return data
}

export const getTableProcessedData = (results, type) => {
  if (!results) return null
  const data = {}
  const totalResultsAmount = results.length
  results.forEach((result, index) => {
    // For each evaluator
    const form = result[`Evaluación de Desempeño Laboral${type}`].empty
    const positionQuestion = form.empty[type === '' ? 2 : 1]
    const positionOptions = positionQuestion.options
    const position = positionOptions[positionQuestion.answers[0].body?.answer]
    const sections = Object.keys(form).filter(key => key !== 'empty')

    sections.forEach(section => {
      // Section of evaluator answers
      if (!data[section]) { data[section] = {} }
      const questions = form[section]
      questions.forEach(question => {
        const oldAverage = data[section]?.[question.text]?.['% de logro']?.split('%')?.[0] || 0
        const questionOptions = question.steps
        const baseAnswer = question.answers[0].body?.answer
        const answerValue = baseAnswer === '0' ? questionOptions.length : baseAnswer
        const answerText = questionOptions[baseAnswer - 1]?.label
        const nombre = parseFloat(oldAverage, 10) + ((answerValue * 20) / totalResultsAmount)

        data[section][question.text] = {
          ...data[section][question.text],
          [`${position}-${index}`]: answerText,
          '% de logro': `${index === totalResultsAmount - 1 ? Math.round(nombre) : nombre}%` }
      })
    })
  })
  return data
}

export const calculateMultipleEvaluationAvgPerformance = (evaluations, type) => {
  const filteredEvaluations = evaluations.filter(evaluation => {
    const questionOfPosition = evaluation[`Evaluación de Desempeño Laboral${type}`].empty.empty[2]
    if (questionOfPosition.answers.length === 0) return false
    return true
  })
  const totals = {}
  const amountOfEvaluations = evaluations.length
  filteredEvaluations.forEach(result => {
    const form = result[`Evaluación de Desempeño Laboral${type}`].empty
    const sections = Object.keys(form).filter(key => key !== 'empty')
    sections.forEach(section => {
      const questions = form[section]
      let totalSection = 0
      const amountInThisSection = questions.length
      questions.forEach(question => {
        const answerValue = question.answers[0].body?.answer * 20 || 0
        totalSection += answerValue
      })
      const averageInThisSection = Math.round(totalSection / amountInThisSection)
      if (!totals[section]) {
        totals[section] = 0
      }
      totals[section] += averageInThisSection
    })
  })
  const normalizedTotals = {}
  Object.entries(totals).forEach(([section, totalSum]) => {
    normalizedTotals[section] = Math.round(totalSum / amountOfEvaluations)
  })
  return normalizedTotals
}

export const retrieveEvaluationImportantInfo = (evaluation, type = '') => {
  const form = evaluation[`Evaluación de Desempeño Laboral${type}`].empty
  const relevantQuestions = form.empty
  const name = relevantQuestions[0]?.answers[0]?.body?.answer
  const date = relevantQuestions[0]?.answers[0]?.updated_at
  const rotationOptions = relevantQuestions[1]?.options
  const rotation = rotationOptions[relevantQuestions[1]?.answers[0]?.body?.answer]
  const positionOptions = relevantQuestions[2]?.options
  const position = positionOptions[relevantQuestions[2]?.answers[0]?.body?.answer]
  const unity = relevantQuestions[3]?.answers[0]?.body?.answer
  return { name, rotation, position, unity, date }
}

export const getUserById = id => {
  const users = store.getState().contenders?.all || []
  const selectedUser = users.find(user => user.user.id === id)
  if (selectedUser) return selectedUser
  return false
}

export const getDataForEachCriteriaOfGroup = (evaluations, type) => {
  const filteredEvaluations = evaluations.filter(evaluation => {
    const questionOfPosition = evaluation[`Evaluación de Desempeño Laboral${type}`].empty.empty[2]
    if (questionOfPosition.answers.length === 0) return false
    return true
  })
  const totals = {}
  filteredEvaluations.forEach(result => {
    const form = result[`Evaluación de Desempeño Laboral${type}`].empty
    const sections = Object.keys(form).filter(key => key !== 'empty')
    sections.forEach(section => {
      const questions = form[section]

      if (!Object.keys(totals).includes(section)) {
        totals[section] = {}
      }

      questions.forEach(question => {
        if (!Object.keys(totals[section]).includes(question.text)) {
          totals[section][question.text] = 0
        }
        const answerValue = (question.answers[0].body?.answer * 20) / evaluations.length
          || 0
        totals[section][question.text] += answerValue
      })
    })
  })
  return totals
}
