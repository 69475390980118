import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Paper, Typography, Divider, Grid } from '@material-ui/core'
import { GoogleLogin } from 'react-google-login'
import { withSnackbar } from 'notistack'
import propTypes from 'prop-types'
import { VerifiedUser } from '@material-ui/icons'
import autobind from '../../Utils/autobind'
import { logoutAction } from '../../Actions/Login'
import { googleSyncAction } from '../../Actions/Settings'
import SingleSettingRow from './SingleSettingRow'

const style = theme => ({
  container: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    textAlign: 'left',
    maxWidth: 500,
  },
  divider: {
    backgroundColor: theme.palette.grey.main,
  },
  header: {
    color: theme.palette.grey.darker,
    fontSize: 14,
    fontWeight: 'bold',
  },
  itemTitle: {
    fontSize: 14,
    color: theme.palette.grey.dark,
  },
  itemDivider: {
    backgroundColor: theme.palette.grey.light,
  },
  itemAction: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  itemLogOut: {
    color: theme.palette.secondary.dark,
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
})

class Settings extends Component {
  constructor(props) {
    super(props)
    autobind(Settings, this)
  }

  handleGoogleLogin(response) {
    const { googleSync, enqueueSnackbar, user } = this.props
    googleSync(response.code, user.id, enqueueSnackbar)
  }

  handleSeePrograms() {
    const { history } = this.props
    history.push('/programs')
  }

  handleSeeExternalUsers() {
    const { history } = this.props
    history.push('/settings/external-users')
  }

  handleEditProfile() {
    const { history } = this.props
    history.push('/settings/profile')
  }

  handleRoleManagement() {
    const { history } = this.props
    history.push('/settings/role-management')
  }

  render() {
    const { classes, logout, user } = this.props
    return (
      <Paper className={classes.container}>
        <Typography className={classes.header}> Configuración general </Typography>
        <Divider classes={{ root: classes.divider }} />
        <SingleSettingRow contextText="Cuenta" actionText="Editar" optionalText={user.name} buttonOnClick={this.handleEditProfile} />
        <Divider classes={{ root: classes.itemDivider }} />
        <SingleSettingRow
          contextText="Descarga"
          actionText="Descargar"
          optionalText="Ficha de Salud"
          ActionComponent={() => (
            <a href={`${process.env.REACT_APP_API_URL}/api/downloads/ficha_salud`} className={classes.itemAction}>
              Descargar
            </a>
          )}
        />
        <Divider classes={{ root: classes.itemDivider }} />
        <SingleSettingRow
          contextText="Descarga"
          actionText="Descargar"
          optionalText="EDPS"
          ActionComponent={() => (
            <a href={`${process.env.REACT_APP_API_URL}/api/downloads/edps`} className={classes.itemAction}>
              Descargar
            </a>
          )}
        />
        <Divider classes={{ root: classes.itemDivider }} />
        <SingleSettingRow
          contextText="Descarga"
          actionText="Descargar"
          optionalText="EDPS (Nueva)"
          ActionComponent={() => (
            <a href={`${process.env.REACT_APP_API_URL}/api/downloads/edps/v2`} className={classes.itemAction}>
              Descargar
            </a>
          )}
        />
        <Divider classes={{ root: classes.itemDivider }} />
        <SingleSettingRow
          contextText="Programas de Intermediación Laboral"
          buttonOnClick={this.handleSeePrograms}
          actionText="Ver"
        />
        <Divider classes={{ root: classes.itemDivider }} />
        <SingleSettingRow
          contextText="Usuarios Link de Redes"
          buttonOnClick={this.handleSeeExternalUsers}
          actionText="Ver"
        />
        <Divider classes={{ root: classes.itemDivider }} />
        <SingleSettingRow
          contextText="Gestión de roles"
          icon={VerifiedUser}
          buttonOnClick={this.handleRoleManagement}
          actionText="Ver"
        />
        <Divider classes={{ root: classes.itemDivider }} />
        <Grid container item justify="space-between" alignItems="center">
          {!user.google_calendar_id && (
            <>
              <Typography className={classes.itemTitle}>
                Sincronizar calendario
              </Typography>
              <GoogleLogin
                clientId="378567307748-sv2l62gf6ffqccu4ipi41no32d5ogg6n.apps.googleusercontent.com"
                responseType="code"
                accessType="offline"
                prompt="consent"
                scope="https://www.googleapis.com/auth/calendar"
                onFailure={res => console.log(res)}
                onSuccess={this.handleGoogleLogin}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                  }}
                >
                  Google Sync
                </div>
              </GoogleLogin>
            </>
          )}
          {user.google_calendar_id && (
            <Typography className={classes.itemTitle} style={{ color: 'grey' }}>
              Calendario sincronizado con Google
            </Typography>
          )}
        </Grid>
        <Divider classes={{ root: classes.itemDivider }} />
        <SingleSettingRow
          contextText="Cerrar Sesión"
          buttonOnClick={() => { logout() }}
          buttonClassname={classes.itemLogOut}
          actionText="Salir"
        />
        <Divider classes={{ root: classes.itemDivider }} />
      </Paper>
    )
  }
}

Settings.propTypes = {
  classes: propTypes.object.isRequired,
  logout: propTypes.func.isRequired,
  user: propTypes.object.isRequired,
  googleSync: propTypes.func.isRequired,
  enqueueSnackbar: propTypes.func.isRequired,
  history: propTypes.object.isRequired
}

const mapStateToProps = state => ({
  user: state.user.current,
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutAction()),
  googleSync: (code, userId, snackbar) => dispatch(googleSyncAction(code, userId, snackbar)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withStyles(style)(Settings)))
