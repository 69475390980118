import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withStyles, Typography, Divider, Paper, IconButton, MenuItem, Menu } from '@material-ui/core'
import propTypes from 'prop-types'
import { MoreVert } from '@material-ui/icons'
import { callSnackbar } from 'Utils/snackbars'
import { getFormResultAction } from 'Actions/Contenders'
import { getHealthLink as getHealthLinkAction } from 'API/Contenders'
import { editEvaluationComment, getEvaluationComment } from 'API/Evaluations'
import autobind from 'Utils/autobind'
import GlobalComment from 'Shared/GlobalComment'
import LoaderAnimator from 'Shared/LoaderAnimator'
import SetViewContext from 'Components/Contenders/Profile/SetViewProvider'
import Diagnosis from './Diagnosis'
import Name from './ResultComponents/Name'
import InterviewHealthTab from './InterviewHealthTab'
import PrevisionEmergencies from './ResultComponents/PrevisionEmergencies'
import HealthCriterium from './ResultComponents/HealthCriterium'
import HealthFormResult from './ResultComponents/HealthFormResult'

const style = () => ({
  title: {
    display: 'flex'
  },
  container: {
    padding: 24,
    position: 'relative',
    textAlign: 'left'
  },
  moreButton: {
    position: 'absolute',
    right: 12,
    top: 12
  },
  menu: {
    position: 'absolute',
    width: '200px',
    right: '12px',
    top: '48px',
  },
  submenu: {
    position: 'absolute',
    width: '150px',
    right: '95%',
    top: '75%',
  }
})

const tabs = {
  'Diagnósticos Físicos': 'Salud física',
  'Diagnósticos Salud Mental': 'Salud mental',
  'Información no relacionada a un diagnóstico': 'Información no asociada a un diagnóstico',
  'Historial de medicamentos': 'Historial de medicamentos',
  'Eventos Traumáticos': 'Eventos traumáticos',
  'Hospitalizaciones no asociadas a eventos traumáticos': 'Hospitalizaciones no asociadas a eventos traumáticos',
}

class HealthTab extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openMenu: null,
      openSubmenu: null,
      healthLink: null,
      disableHealthLink: false,
      comment: ''
    }

    autobind(HealthTab, this)
  }

  async componentDidMount() {
    const { contender, getFormResult, event } = this.props
    const body = {
      f_id: event.form_id,
      e_id: event.event_id,
      user_id: contender.user.id,
      type: event.route,
      withanswers: true
    }
    getFormResult(body)

    const commentBody = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contender.user.id
    }
    const response = await getEvaluationComment(commentBody)
    if (response.data.status !== 'success') return null
    let disableHealthLink = false
    const healthLinkResponse = await getHealthLinkAction(contender.contender.id)
    if (response.data.status !== 'success') { disableHealthLink = true }
    return this.setState({
      comment: response.data.info.comment,
      healthLink: healthLinkResponse.data.info,
      disableHealthLink
    })
  }

  handleSetView(View, name) {
    return () => {
      const { setView } = this.context
      setView(View, name)
    }
  }

  handleOpenMenu(id) {
    return () => {
      let element = id
      if (id !== null) {
        element = document.getElementById(id)
      }
      this.setState({ openMenu: element })
    }
  }

  handleOpenSubMenu(id) {
    return () => {
      let element = id
      if (id !== null) {
        element = document.getElementById(id)
      }
      this.setState({ openSubmenu: element })
    }
  }

  handleChange(event) {
    const { target } = event
    this.setState({ comment: target.value })
  }

  handleSave() {
    const { comment } = this.state
    const { event, contender } = this.props
    const body = {
      event_id: event.event_id,
      form_id: event.form_id,
      user_id: contender.user.id,
      comment
    }
    editEvaluationComment(body)
  }

  handleCopyLink() {
    const { healthLink } = this.state
    const element = document.getElementById('secret-input')
    element.value = healthLink
    element.select()
    element.setSelectionRange(0, 99999)
    document.execCommand('copy');
    callSnackbar('Enlace copiado', 'success')
  }

  floatingButton() {
    const { classes, event } = this.props
    const { openMenu, openSubmenu, disableHealthLink } = this.state
    return (
      <div>
        <IconButton size="small" color="primary" className={classes.moreButton} onClick={this.handleOpenMenu('menu')} id="menu">
          <MoreVert />
        </IconButton>
        <Menu
          anchorEl={openMenu}
          open={openMenu !== null}
          onClose={this.handleOpenMenu(null)}
        >
          <MenuItem>Descargar</MenuItem>
          <MenuItem disabled={disableHealthLink} onClick={this.handleCopyLink}>
            Copiar Link
          </MenuItem>
          <input style={{ position: 'absolute', opacity: 0, zIndex: -10 }} id="secret-input" />
          <MenuItem
            disabled={Object.keys(event.dynamic_criterium_id).length === 0}
            onClick={this.handleOpenSubMenu('submenu')}
            id="submenu"
          >
            Seleccionar para Entrevista
          </MenuItem>
          <Menu
            anchorEl={openSubmenu}
            open={openSubmenu !== null}
            onClose={this.handleOpenSubMenu(null)}
          >
            <MenuItem
              onClick={this.handleSetView(<InterviewHealthTab event={event} showAll title="Individual" />, 'Selección entrevista Individual')}
            >
              Individual

            </MenuItem>
            <MenuItem
              onClick={this.handleSetView(<InterviewHealthTab event={event} showAll title="Familiar" />, 'Selección entrevista Familiar')}
            >
              Familiar

            </MenuItem>
          </Menu>
        </Menu>
      </div>
    )
  }

  renderTabs() {
    const { results, classes } = this.props
    if (Array.isArray(results)) { return null }
    return Object.entries(tabs).map(([name, key]) => {
      const result = results.results[key]
      let diagnostics = <Typography variant="h2" style={{ padding: 24, textAlign: 'left' }}>No hay resultados...</Typography>
      if (result) {
        diagnostics = result.map(diagnosis => (
          <Diagnosis
            key={diagnosis.question_text}
            answer={diagnosis.body}
            questions={diagnosis.subquestions || []}
            title={diagnosis.question_text}
          />
        ))
      }
      return (
        <React.Fragment key={name}>
          <div className={classes.title} key={name}>
            <Typography variant="h1" style={{ marginRight: 24 }}>{name}</Typography>
            <Divider style={{ flexGrow: 1, background: '#bababa', height: 2 }} />
          </div>
          {diagnostics}
        </React.Fragment>
      )
    })
  }

  render() {
    const { classes, results, event } = this.props
    const { forms } = results
    const form = forms ? forms['Ficha de Salud'] : false
    if (form) {
      const saludFisica = form['Parte I']['Salud Física']
      const saludMental = form['Parte II']['Salud Mental']
      const medicamentos = form['Parte II'].Medicamentos
      const hospitalizaciones = form['Parte II'].Hospitalizaciones
      const eventosImportantes = form['Parte II']['Eventos importantes']
      const evaluacionSensorial = form['Parte II']['Evaluación Sensorial']
      return (
        <Paper className={classes.container}>
          <Name form={form} />
          <div style={{ margin: '12px 0' }}>
            <GlobalComment event={event} />
          </div>
          <PrevisionEmergencies form={form} />
          <HealthCriterium form={saludFisica} title="Salud Física" />
          <HealthCriterium form={saludMental} title="Salud Mental" />
          <HealthCriterium form={medicamentos} title="Medicamentos" />
          <HealthCriterium form={hospitalizaciones} title="Hospitalizaciones" />
          <HealthCriterium form={eventosImportantes} title="Eventos Importantes" />
          <HealthFormResult form={evaluacionSensorial} title="Evaluación Sensorial" />
          {this.floatingButton()}
        </Paper>
      )
    }
    return (
      <LoaderAnimator loading />
    )
  }
}

HealthTab.contextType = SetViewContext

HealthTab.propTypes = {
  getFormResult: propTypes.func.isRequired,
  classes: propTypes.object.isRequired,
  contender: propTypes.object.isRequired,
  results: propTypes.object,
  event: propTypes.object.isRequired,
}

HealthTab.defaultProps = {
  results: { results: {} }

}

const mapStateToProps = state => ({
  results: state.contenders.results,
  contender: state.contenders.selected
})

const mapDispatchToProps = dispatch => ({
  getFormResult: body => dispatch(getFormResultAction(body)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(HealthTab))
