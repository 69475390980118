import React, { Component } from 'react'
import { withStyles } from '@material-ui/core'
import propTypes from 'prop-types'
import autobind from 'Utils/autobind'
import SectionTitle from './SectionTitle'
import ProgramDownloadCard from './ProgramDownloadCard'
import CompetencyChartsDialog from './CompetencyChartsDialog'
import PerformanceChartsDialog from './PerformanceChartsDialog'
import AutoevaluationChartsDialog from './AutoevaluationChartsDialog'
import GlobalDownloadCard from './GlobalDownloadCard.jsx'

const style = () => ({
  container: {
    padding: 24
  }
})

function handleDownloadHealth() {
  const url = `${process.env.REACT_APP_API_URL}/api/downloads/ficha_salud`
  const a = document.createElement('a')
  a.href = url
  a.click()
}

function handleDownloadEDPS() {
  const url = `${process.env.REACT_APP_API_URL}/api/downloads/edps`
  const a = document.createElement('a')
  a.href = url
  a.click()
}

function handleDownloadEDPSv2() {
  const url = `${process.env.REACT_APP_API_URL}/api/downloads/edps/v2`
  const a = document.createElement('a')
  a.href = url
  a.click()
}

function renderGlobalDownloads() {
  return (
    <div>
      <GlobalDownloadCard name="Reporte Ficha de Salud" onClick={handleDownloadHealth} />
      <GlobalDownloadCard name="Reporte Evaluación de Desarrollo Personal y Social" onClick={handleDownloadEDPS} />
      <GlobalDownloadCard name="Reporte Evaluación de Desarrollo Personal y Social (Nueva)" onClick={handleDownloadEDPSv2} />
      <GlobalDownloadCard name="Información de Dashboard" disabled />
      <GlobalDownloadCard name="Reporte Caracterización Socio-Demográfica" disabled />
    </div>
  )
}
class DownloadsContainer extends Component {
  constructor() {
    super()
    autobind(DownloadsContainer, this)
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.container}>
        <SectionTitle title="Descargas Globales" />
        {renderGlobalDownloads()}
        <SectionTitle title="Descargas por Programas" />
        <div>
          <ProgramDownloadCard name="Informe de Evaluación de Competencias" dialog={CompetencyChartsDialog} />
          <ProgramDownloadCard name="Informe de Evaluación de Desempeño Laboral" dialog={PerformanceChartsDialog} />
          <ProgramDownloadCard name="Informe Autoevaluación" dialog={AutoevaluationChartsDialog} />
        </div>
      </div>
    );
  }
}

DownloadsContainer.propTypes = {
  classes: propTypes.object.isRequired
}

DownloadsContainer.defaultProps = {

}
export default withStyles(style)(DownloadsContainer)
