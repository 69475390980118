import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Button, Divider, Paper, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'
import FloatingComment from 'Shared/FloatingComment'
import RecurrentContext from '../RecurrentEvaluations/RecurrentContext'
import FlexibleInput from '../../Utils/FieldRenderer'
import autobind from '../../Utils/autobind'
import { sendAnswersAction } from '../../Actions/Events'
import AutoSaver from '../../Shared/AutoSaver'
import { changeEvaluationGroupStatus, changeFormStatus, getFormEvaluation } from '../../API/Recurrent'
import LoaderAnimator from '../../Shared/LoaderAnimator'

const style = theme => ({
  container: {
    padding: 24,
    margin: 'auto',
    textAlign: 'left',
    maxWidth: 900,
    marginTop: 24,
    position: 'relative'
  },
  formContainer: {
    padding: 24,
    position: 'relative'
  },
  question: {
    margin: '12px 0'
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginBottom: 24
  },
  button: {
    textAlign: 'end'
  },
  bold: {
    fontWeight: 600
  },
  evaluations: {
    margin: '24px 0',
  },
  evaluation: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  criteria: {
    margin: '32px 0 24px 0',
    '& > h2': {
      fontWeight: 600,
      color: theme.palette.primary.main
    }
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  indicators: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  indicator: {
    margin: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 12,
    minWidth: 150,
    maxWidth: 150,
    '& > *': {
      margin: '6px 0'
    },
    '& > h6': {
      textAlign: 'center'
    }
  },
  indBox: {
    background: theme.palette.grey.light,
    padding: 12,
    margin: 12,
    width: '100%',
    borderRadius: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      margin: '6px 0'
    },
  },
  instructions: {
    margin: '24px 0'
  },
  instructionsText: {
    textAlign: 'center',
    '& > *': {
      margin: '12px 0',
      fontSize: 13
    }
  },
  pageIndicator: {
    position: 'absolute',
    top: 12,
    right: 12
  },
  evInfo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  instruction: {
    border: `1px solid ${theme.palette.primary.main}`,
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    '& > h1': {
      minWidth: 225,
      textAlign: 'center',
      fontSize: 16
    },
    '& > h6': {
      textAlign: 'start'
    }
  }
})

class EDPSEvaluation extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      evaluation: null,
      page: 0,
      ready: false,
      name: '',
      type: 'normal'
    }
    autobind(EDPSEvaluation, this)
  }

  async componentDidMount() {
    const { match } = this.props
    const { form_id: formId, evaluation_id: evaluationId } = match.params
    const body = {
      form_id: formId,
      evaluation_id: evaluationId,
    }

    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');

    const response = await getFormEvaluation(body)
    if (response.data.status !== 'success') return false
    const evaluation = response.data.info
    const mainForm = evaluation[`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`].empty
    const allQuestions = Object.values(mainForm).flat()
    const params = {}
    let name = ''
    allQuestions.forEach(question => {
      const answer = question?.answers[0]
      const answerBody = answer?.body?.answer
      const id = answer?.id
      params[id] = answerBody
      if (question?.text === 'Nombre de quién responde la ficha') {
        name = answerBody
      }
    })
    return this.setState({ evaluation, params, name, type })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  handleChangePage(option) {
    return async () => {
      const { match } = this.props
      const {
        user_type: userType,
        group_id: groupId,
        form_id: formId,
        evaluation_id: evaluationId
      } = match.params
      const { page } = this.state
      this.handleSave()
      const title = document.getElementById('title')
      setTimeout(() => { title.scrollIntoView({ behavior: 'smooth' }) }, 400)
      if (option === 'next' && page < 2) return this.setState({ page: page + 1 })
      if (option === 'prev' && page > 0) return this.setState({ page: page - 1 })
      if (option === 'next' && page === 2) {
        await changeFormStatus({ evaluation_id: evaluationId, status: 'check' })
        if (userType === '2') {
          const checkBody = {
            form_id: formId,
            evaluation_group_id: groupId,
            status: 'check'
          }
          changeEvaluationGroupStatus(checkBody)
        }
        this.setState({ ready: true })
      }
      return true
    }
  }

  async handleSave() {
    const { params } = this.state
    const { sendAnswers, match } = this.props
    const { event_id: eventId } = match.params
    const answers = { ...params }
    const body = Object.entries(answers).filter(([key, value]) => !!value).map(([key, value]) => ({
      id: key,
      body: { answer: value },
      comment: '',
    }))
    await sendAnswers({ answers: body }, eventId)
    return true
  }

  checkIfPageNotReady() {
    const { params, evaluation, page, type } = this.state

    if (!evaluation) {
      return false
    }
    const mainForm = evaluation[`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`].empty
    const criteria = Object.keys(mainForm).filter(crit => crit !== 'empty').slice(page * 3, (page + 1) * 3)
    let isFullyAnswer = true
    criteria.forEach(crit => {
      const questionsInfo = mainForm[crit]
      questionsInfo.forEach(question => {
        const name = question?.answers[0]?.id
        const answer = params[name]
        if (!answer) { isFullyAnswer = false }
      })
    })
    return !isFullyAnswer
  }

  renderQuestions() {
    const { classes, match } = this.props
    const { params, evaluation, page, type } = this.state
    const { user_type: userType } = match.params

    function getText(user, question) {
      if (user === '0') return question.text
      if (user === '1') return question.companion_text
      return question.handler_text
    }

    if (!evaluation) {
      return (
        <div style={{ height: 300 }}>
          <LoaderAnimator loading />
        </div>
      )
    }
    const mainForm = evaluation[`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`].empty
    const criteria = Object.keys(mainForm).filter(crit => crit !== 'empty').slice(page * 3, (page + 1) * 3)
    return criteria.map(crit => {
      const questionsInfo = mainForm[crit]
      const questions = questionsInfo.map((question, index) => {
        const name = question?.answers[0]?.id
        const info = { ...question }
        info.name = name
        info.text = `${index + 1} - ${getText(userType, question)}`
        return (
          <div className={classes.question}>
            <FlexibleInput
              complete={info}
              value={params[name]}
              onChange={this.handleChange}
              disableNoObservation
            />
          </div>
        )
      })
      return (
        <div className={classes.criteria}>
          <Typography variant="h2">{crit}</Typography>
          <Divider />
          {questions}
        </div>
      )
    })
  }

  renderInstructions() {
    const { match, classes } = this.props
    const { user_type: userType } = match.params
    if (userType === '0') {
      return (
        <div className={classes.instructionsText}>
          <Typography variant="subtitle1">
            Instrucciones: debes llenar este formulario según lo que tu crees respecto de tu vida.
            Es importante que sepas que no hay respuestas correctas ni incorrectas y
            que son confidenciales.
          </Typography>
          <div className={classes.indicators}>
            <div className={classes.indicator}>
              <div className={classes.indBox}>
                <img src="/nunca.svg" alt="nunca" />
                <Typography variant="subtitle1">Nunca</Typography>
              </div>
              <Typography variant="subtitle1">
                ‘Nunca’ si ningún día te ocurre lo que se dice en la pregunta.
              </Typography>
            </div>
            <div className={classes.indicator}>
              <div className={classes.indBox}>
                <img src="/aveces.svg" alt="aveces" />
                <Typography variant="subtitle1">A Veces</Typography>
              </div>
              <Typography variant="subtitle1">
                ‘Algunas veces’ si lo que se dice en el ítem te ocurre alguna vez,
                pero no la mayoría de las veces (es decir, con poca frecuencia;
                por ejemplo, algún día que otro).
              </Typography>
            </div>
            <div className={classes.indicator}>
              <div className={classes.indBox}>
                <img src="/frecuentemente.svg" alt="frec" />
                <Typography variant="subtitle1">Frecuentemente</Typography>
              </div>
              <Typography variant="subtitle1">
                ‘Frecuentemente’ si lo que dice en el ítem te ocurre la mayoría
                de las veces (es decir, con mucha frecuencia; por ejemplo, casi todos los días).
              </Typography>
            </div>
            <div className={classes.indicator}>
              <div className={classes.indBox}>
                <img src="/siempre.svg" alt="siempre" />
                <Typography variant="subtitle1">Siempre</Typography>
              </div>
              <Typography variant="subtitle1">
                ‘Siempre’ si lo que dice la pregunta te ocurre siempre
                (por ejemplo, todos los días).
              </Typography>
            </div>
          </div>
        </div>
      )
    }
    if (userType === '1') {
      return (
        <div className={classes.instructionsText}>
          <Typography variant="subtitle1">
            INSTRUCCIONES: debe llenar esta evaluación de acuerdo a la percepción
            que tiene de su familiar. Si tiene alguna duda o no sabe una respuesta
            puede preguntarle a otro familiar o cercano de la persona
          </Typography>
          <div className={classes.instruction}>
            <Typography variant="h1" color="primary">NUNCA</Typography>
            <Typography variant="subtitle1">
              Si la persona nunca hace o nunca le ocurre lo que se especifica
              en el contenido del ítem. Por ejemplo, para responder al ítem ‘Duerme bien’,
              deberá marcar la opción NUNCA si todos los días de la semana la persona tiene
              problemas para dormir.
            </Typography>
          </div>
          <div className={classes.instruction}>
            <Typography variant="h1" color="primary">ALGUNAS VECES</Typography>
            <Typography variant="subtitle1">
              Si la persona hace alguna vez, o le ocurre, lo que se especifica
              en el contenido del ítem algunas veces, pero no en la mayoría de las ocasiones.
              Por ejemplo, para responder al ítem ‘Duerme bien’, deberá marcar la opción ALGUNAS
              VECES si es el caso que duerme bien sólo 2 ó 3 días por semana.
            </Typography>
          </div>
          <div className={classes.instruction}>
            <Typography variant="h1" color="primary">FRECUENTEMENTE</Typography>
            <Typography variant="subtitle1">
              Si la persona hace frecuentemente, o le ocurre,
              lo que se especifica en el ítem; es decir, en la mayoría de las ocasiones.
              Por ejemplo, para responder al ítem ‘Duerme bien’, deberá marcar la opción
              FRECUENTEMENTE si, por ejemplo, duerme bien entre 4 y 6 días por semana.
            </Typography>
          </div>
          <div className={classes.instruction}>
            <Typography variant="h1" color="primary">SIEMPRE</Typography>
            <Typography variant="subtitle1">
              Si la persona hace o le ocurre siempre lo que se especifica en
              el ítem. Por ejemplo, para responder al ítem ‘Duerme bien’, deberá marcar
              la opción SIEMPRE si, duerme bien todos los días (aunque haya alguna noche
              excepcional en la que tenga problemas; por ejemplo, si la persona ha dormido
              mal un par de veces al año, por ejemplo, por una indigestión, puede seguir
              contestando SIEMPRE).n de acuerdo a los últimos 3 meses.
              Recuerde que las respuestas son según lo que realiza su hijo o familiar.
            </Typography>
          </div>
        </div>
      )
    }
    return (
      <div className={classes.instructionsText}>
        <Typography variant="subtitle1">
          INSTRUCCIONES: debe llenar esta evaluación de acuerdo a su percepción
          del usuario. Si tiene alguna duda o no sabe una respuesta puede preguntarle
          a un familiar o cercano de la persona.
        </Typography>
        <div className={classes.instruction}>
          <Typography variant="h1" color="primary">NUNCA</Typography>
          <Typography variant="subtitle1">
            Si la persona nunca hace o nunca le ocurre lo que se especifica en
            el contenido del ítem. Por ejemplo, para responder al ítem ‘Duerme bien’,
            deberá marcar la opción NUNCA si todos los días de la semana la persona
            tiene problemas para dormir.
          </Typography>
        </div>
        <div className={classes.instruction}>
          <Typography variant="h1" color="primary">ALGUNAS VECES</Typography>
          <Typography variant="subtitle1">
            Si la persona hace alguna vez, o le ocurre, lo que se
            especifica en el contenido del ítem algunas veces, pero no en la mayoría
            de las ocasiones. Por ejemplo, para responder al ítem ‘Duerme bien’,
            deberá marcar la opción ALGUNAS VECES si es el caso que duerme bien sólo 2 ó 3
            días por semana.
          </Typography>
        </div>
        <div className={classes.instruction}>
          <Typography variant="h1" color="primary">FRECUENTEMENTE</Typography>
          <Typography variant="subtitle1">
            Si la persona hace frecuentemente, o le ocurre, lo que
            se especifica en el ítem; es decir, en la mayoría de las ocasiones. Por
            ejemplo, para responder al ítem ‘Duerme bien’, deberá marcar la opción
            FRECUENTEMENTE si, por ejemplo, duerme bien entre 4 y 6 días por semana
          </Typography>
        </div>
        <div className={classes.instruction}>
          <Typography variant="h1" color="primary">SIEMPRE</Typography>
          <Typography variant="subtitle1">
            Si la persona hace o le ocurre siempre lo que se especifica en
            el ítem. Por ejemplo, para responder al ítem ‘Duerme bien’, deberá marcar
            la opción SIEMPRE si, duerme bien todos los días (aunque haya alguna noche
            excepcional en la que tenga problemas; por ejemplo, si la persona ha dormido
            mal un par de veces al año, por ejemplo,
            por una indigestión, puede seguir contestando SIEMPRE).
          </Typography>
        </div>
      </div>
    )
  }

  render() {
    const { classes, match } = this.props
    const { page, name } = this.state
    const {
      cont_name: contenderName, form_id: formId, evaluation_id: evaluationId, event_id: eventId
    } = match.params
    const decodedName = atob(contenderName)
    const { ready } = this.state
    const eventInfo = {
      event_id: eventId,
      form_id: formId
    }
    if (ready) {
      return (
        <div className={classes.container}>
          <Paper className={classes.formContainer}>
            <Typography variant="h1">¡Gracias por responder!</Typography>
          </Paper>
        </div>
      )
    }
    return (
      <Paper className={classes.container}>
        <FloatingComment event={eventInfo} evaluation={evaluationId} />
        <div className={classes.title}>
          <Typography variant="h1" id="title" color="primary">Evaluación de Desarrollo Personal y Social</Typography>
          <Typography variant="body1">{decodedName}</Typography>
        </div>
        <div className={classes.evInfo}>
          <Typography variant="h2">
            Usuario:
            {' '}
            {name}
          </Typography>
          <Typography variant="h2">
            Fecha:
            {' '}
            {moment(new Date()).format('DD/MM/YYYY')}
          </Typography>
        </div>
        <div className={classes.pageIndicator}>
          <Typography variant="h2">{`${page + 1}/3`}</Typography>
        </div>

        <div className={classes.instructions}>
          {this.renderInstructions()}
        </div>
        {this.renderQuestions()}
        <div className={classes.buttons}>
          <Button color="secondary" variant="contained" onClick={this.handleChangePage('prev')}>
            Atras
          </Button>
          <Button color="secondary" variant="contained" onClick={this.handleChangePage('next')} disabled={this.checkIfPageNotReady()}>
            {page < 2 ? 'Siguiente' : 'Terminar'}
          </Button>
        </div>
        <AutoSaver onSave={this.handleSave} timeout={60000} />
      </Paper>
    )
  }
}

EDPSEvaluation.contextType = RecurrentContext

EDPSEvaluation.propTypes = {
  classes: propTypes.object.isRequired,
  sendAnswers: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
}

EDPSEvaluation.defaultProps = {
}

const mapDispatchToProps = dispatch => ({
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id))
})

export default connect(null, mapDispatchToProps)(withStyles(style)(EDPSEvaluation))
