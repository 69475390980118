/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react'
import propTypes from 'prop-types'
import { Button, Collapse, Divider, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import moment from 'moment'
import { CheckCircleOutline, HighlightOffOutlined, KeyboardArrowDown } from '@material-ui/icons'
import RecurrentContext from '../RecurrentEvaluations/RecurrentContext'
import FlexibleInput from '../../Utils/FieldRenderer'
import autobind from '../../Utils/autobind'
import { sendAnswersAction } from '../../Actions/Events'
import { changeEvaluationGroupStatus, getEvaluationGroup, getFormEvaluation } from '../../API/Recurrent'
import Select from '../../Shared/Fields/Select'

const style = theme => ({
  container: {
    padding: 24,
    margin: 'auto',
    textAlign: 'left',
    maxWidth: 700,
    marginTop: 24
  },
  formContainer: {
    padding: 24,
    position: 'relative'
  },
  question: {
    margin: '12px 0'
  },
  title: {
    textAlign: 'center',
    color: theme.palette.primary.main,
    marginBottom: 24
  },
  button: {
    textAlign: 'end'
  },
  bold: {
    fontWeight: 600
  },
  evaluations: {
    margin: '24px 0',
  },
  evaluation: {
    padding: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  ev: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr 1.5fr 1fr',
    padding: '12px 0',
    '& > *:last-child': {
      textAlign: 'end'
    }
  },
  spaced: {
    margin: '12px 0'
  },

})

class SelectUser extends Component {
  constructor() {
    super()
    this.state = {
      params: {},
      allEvaluations: [],
      allEvaluationsBody: [],
      ready: false,
      selectedType: 0,
      open: true,
      type: 'normal',
      loading: false
    }
    autobind(SelectUser, this)
  }

  async componentDidMount() {
    const { match } = this.props
    const { form_id: formId, group_id: groupId } = match.params

    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');

    const body = {
      form_id: formId,
      evaluation_group_id: groupId,
    }
    this.setState({ loading: true })
    const response = await getEvaluationGroup(body)
    if (response.data.status !== 'success') return false
    const { evaluations = [] } = response.data.info
    if (evaluations.length === 0) return false
    const evaluationBody = {
      form_id: formId,
      evaluation_id: evaluations[0].id,
    }
    const secondEvaluationBody = {
      form_id: formId,
      evaluation_id: evaluations[1].id,
    }
    const evaluationResponse = await getFormEvaluation(evaluationBody)
    if (evaluationResponse.data.status !== 'success') return false

    const secondEvaluationResponse = await getFormEvaluation(secondEvaluationBody)

    const allEvaluationsBody = []
    allEvaluationsBody.push(evaluationResponse.data.info)
    allEvaluationsBody.push(secondEvaluationResponse.data.info)
    // Simulate click on first evaluation

    await this.setState({
      allEvaluations: evaluations,
      evaluation: evaluationResponse.data.info,
      type,
      allEvaluationsBody,
      loading: false
    })
    const simulatedEvent = { target: { value: 0 } }
    return this.handleChangeEvaluation(simulatedEvent)
  }

  handleToggleOpen() {
    const { open } = this.state
    this.setState({ open: !open })
  }

  async handleChangeEvaluation(event) {
    const { target } = event
    const { match } = this.props
    const { allEvaluations, type } = this.state
    const { form_id: formId } = match.params
    const evaluationBody = {
      form_id: formId,
      evaluation_id: allEvaluations[target.value].id,
    }
    const evaluationResponse = await getFormEvaluation(evaluationBody)
    if (evaluationResponse.data.status !== 'success') return false
    const evaluation = evaluationResponse.data.info
    const mainForm = evaluation?.[`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`]?.empty
    const initialQuestions = mainForm?.empty || []
    const initialParams = {}
    initialQuestions.forEach(question => {
      const answer = question?.answers?.[0]
      const body = answer?.body?.answer
      const id = answer?.id
      initialParams[id] = body
    })
    return this.setState({
      evaluation: evaluationResponse.data.info,
      selectedType: target.value,
      params: initialParams
    })
  }

  handleChange(event) {
    const { target } = event
    const { params } = this.state
    params[target.name] = target.value
    this.setState({ params })
  }

  async handleSave() {
    const { params, allEvaluations, selectedType, type } = this.state
    const { sendAnswers, match, history } = this.props
    const { event_id: eventId, form_id: formId, group_id: groupId } = match.params
    const answers = { ...params }
    const body = Object.entries(answers).map(([key, value]) => ({
      id: key,
      body: { answer: value },
      comment: '',
    }))
    await sendAnswers({ answers: body }, eventId)
    const { cont_name: contenderName } = match.params
    const url = `/edps/evaluation/${eventId}/${groupId}/${allEvaluations[selectedType].id}/${formId}/${contenderName}/${selectedType}?type=${type}`
    history.push(url)
    return true
  }

  async handleEnd() {
    const { match, history } = this.props
    const { form_id: formId, group_id: groupId } = match.params
    const checkBody = {
      form_id: formId,
      evaluation_group_id: groupId,
      status: 'check'
    }
    await changeEvaluationGroupStatus(checkBody)
    history.push('/contenders/')
  }

  renderEvaluations() {
    const { evaluation, params, selectedType, type } = this.state
    const { classes } = this.props
    const mainForm = evaluation?.[`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`]?.empty
    if (!mainForm) return null
    const initialQuestions = mainForm?.empty
    const selectInfo = {
      name: 'formType',
      text: '1 - Usuario',
      options: ['Autoevaluación (usuario)', 'Familiar (acompañante)']
    }
    const questions = initialQuestions.map((question, index) => {
      const name = question?.answers[0]?.id
      const info = { ...question }
      info.name = name
      info.text = `${index + 2} - ${question.text}`
      return (
        <div className={classes.question}>
          <FlexibleInput
            complete={info}
            value={params[name]}
            onChange={this.handleChange}
          />
        </div>
      )
    })
    return (
      <>
        <div>
          <Select
            complete={selectInfo}
            value={selectedType}
            onChange={this.handleChangeEvaluation}
          />
        </div>
        {questions}
      </>
    )
  }

  renderCompletedEvaluations() {
    const { allEvaluations, allEvaluationsBody, type } = this.state
    const { classes } = this.props

    const names = ['Autoevaluación', 'Familiar']
    return allEvaluations.map((evaluation, index) => {
      const mainForm = allEvaluationsBody[index]?.[`Evaluación de desarrollo personal y social${type === 'new' ? ' (Nueva)' : ''}`]?.empty
      console.log(mainForm)
      if (!mainForm) return null
      const initialQuestions = mainForm?.empty
      const question = initialQuestions[0]
      const evName = question?.answers[0]?.body?.answer
      if (index >= 2) return null
      return (
        <div className={classes.ev}>
          <Typography variant="subtitle1">
            {names[index]}
          </Typography>
          <Typography variant="subtitle1">{ `Respondida por: ${evName || '-'}`}</Typography>
          <div>
            {evaluation.status === 'check' ? <CheckCircleOutline /> : <HighlightOffOutlined />}
          </div>
        </div>
      )
    })
  }

  render() {
    const { classes, match, user } = this.props
    const { cont_name: contenderName } = match.params
    const decodedName = atob(contenderName)
    const { ready, open, allEvaluations, loading } = this.state

    const isHandler = !!user?.current?.id
    const isEveryEvaluationReady = allEvaluations.every((evaluation, index) => index >= 2 || evaluation.status === 'check')

    if (ready) {
      return (
        <div className={classes.container}>
          <Paper className={classes.formContainer}>
            <Typography variant="h1">¡Gracias por responder!</Typography>
          </Paper>
        </div>
      )
    }
    return (
      <>
        <Paper className={classes.container}>
          <Typography variant="h1">Evaluación de Desarrollo Personal y Social</Typography>
          <Divider />
          <Typography style={{ textAlign: 'end', fontStyle: 'italic' }} variant="body1">{moment(new Date()).format('DD [de] MMMM [del] YYYY')}</Typography>
          <Typography style={{ textAlign: 'end' }} variant="subtitle1">
            Trabajador:
            {' '}
            {decodedName}
          </Typography>
          {isHandler
            && (
              <>
                <Typography variant="h3" className={classes.spaced}>Evaluaciones realizadas</Typography>
                {this.renderCompletedEvaluations()}
                <div className={classes.button}>
                  <Button
                    size="small"
                    color="primary"
                    variant="contained"
                    onClick={this.handleEnd}
                    style={{ margin: '16px 0 24px' }}
                    disabled={!isEveryEvaluationReady || loading}
                  >
                    Terminar evaluación
                  </Button>
                </div>
                <Divider />
              </>
            )}
          <div>
            {isHandler
              && (
                <div
                  className={classes.evaluation}
                  style={{ padding: 0 }}
                  onClick={this.handleToggleOpen}
                >
                  <Typography variant="subtitle1">Revisar evaluaciones</Typography>
                  <IconButton size="small">
                    <KeyboardArrowDown />
                  </IconButton>
                </div>
              )}
            <Collapse in={open || !isHandler}>
              <div className={classes.evaluations}>
                {this.renderEvaluations()}
              </div>
              <div className={classes.button}>
                <Button disabled={loading} color="secondary" variant="contained" className={classes.spaced} onClick={this.handleSave}>
                  Ingresar
                </Button>
              </div>
            </Collapse>
          </div>
        </Paper>
      </>
    )
  }
}

SelectUser.contextType = RecurrentContext

SelectUser.propTypes = {
  classes: propTypes.object.isRequired,
  sendAnswers: propTypes.func.isRequired,
  match: propTypes.object.isRequired,
  history: propTypes.object.isRequired,
  user: propTypes.object.isRequired,
}

SelectUser.defaultProps = {
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = dispatch => ({
  sendAnswers: (body, id) => dispatch(sendAnswersAction(body, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(SelectUser))
